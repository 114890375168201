import { AfterViewInit, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MAT_RADIO_DEFAULT_OPTIONS, MatRadioChange } from '@angular/material/radio';
import { AddEditDefinitionDialogDataModel } from 'src/app/models/add-edit-definition-dialog-data-model';
import { ContractDefinition } from 'src/app/models/ContractDefinition';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { ContractDefinitionService } from 'src/app/services/contract-definition.service';

import { ActionItem } from '../../shared/contract-details/contract-details.component';

export interface CheckboxItem {
  name: string;
  checked: boolean;
}

@Component({
  selector: 'app-add-edit-definition-dialog',
  templateUrl: './add-edit-definition-dialog.component.html',
  styleUrls: ['./add-edit-definition-dialog.component.less'],
  providers: [ContractDefinitionService, {
    provide: MAT_RADIO_DEFAULT_OPTIONS,
    useValue: { color: 'accent' }
  }]
})

export class AddEditDefinitionDialogComponent implements OnInit, AfterViewInit {
  @Output() OnSaveClick: EventEmitter<any> = new EventEmitter<any>();
  editMode = true;
  addMode = true;
  saveDisabled = true;
  isLoadingResults = false;

  pageTitle: string;

  definition: ContractDefinition

  definitionForm: UntypedFormGroup
  definitionToEdit: ContractDefinition

  dataSource: any;

  contractId: number;
  definitionId: number;
  clientId: number;
  mode: string;

  brandGenericItems: string[]
  brandSelection: string

  monyItems: string[]
  monyDisplay: string

  tbgItems: string[]
  tbgDisplay: string

  dawItems: string[]
  dawSelection: string
  dawCheckboxes: CheckboxItem[]
  dawCheckboxesOriginal: CheckboxItem[]

  public readonly viewFeatures = [
    { feature: 'Post::AddContractDetails', name: 'add', accessible: false },
    { feature: 'Put::EditContractDetails', name: 'edit', accessible: false },
  ]

  constructor(
    private builder: UntypedFormBuilder,
    private definitionService: ContractDefinitionService,
    private authorizationService: AuthorizationService,
    public dialogRef: MatDialogRef<AddEditDefinitionDialogDataModel>,
  @Inject(MAT_DIALOG_DATA) public data: AddEditDefinitionDialogDataModel)  {
    this.contractId = this.data.contractId;
    this.definitionId = this.data.definition.definitionId
  }

  ngOnInit(): void {
    this.checkFeatures();
    this.clearForm();

    this.mode = this.data.action;
    this.contractId = this.data.contractId;
    this.clientId = this.data.clientId;

    //  Initialize button lists
    this.brandGenericItems = [ "Brand", "Generic" ]
    this.monyItems = [ "M", "O", "N", "Y", "None"]
    this.tbgItems = [ "T", "B", "G", "None" ]
    this.dawItems = [ "0", "1", "2", "3", "4", "5", "6", "7", "8", "9" ]

    if (this.mode === "edit") {
      this.editMode = true;
      this.addMode = false;
      this.pageTitle = 'Edit Contract Definition';
      this.definitionId = this.data.definition.definitionId
      this.definitionToEdit = this.data.definition;
      this.monyDisplay = this.definitionToEdit.mony ?? 'None'
      this.tbgDisplay = this.definitionToEdit.tbg ?? 'None'
    }

    if (this.mode === "add") {
      this.editMode = false;
      this.addMode = true;
      this.pageTitle = 'Add Contract Definition';
      this.monyDisplay = 'None';
      this.tbgDisplay = 'None';
    }
    this.clearForm();
    this.populateForm();
  }

  private clearForm(): void {
    this.definitionForm = this.builder.group({
      definitionTypeGroup: [
        '',
        Validators.compose([Validators.required])
      ],
      monyGroup:[''],
      tbgGroup:[''],
      d0: this.builder.control(''),
      d1: this.builder.control(''),
      d2: this.builder.control(''),
      d3: this.builder.control(''),
      d4: this.builder.control(''),
      d5: this.builder.control(''),
      d6: this.builder.control(''),
      d7: this.builder.control(''),
      d8: this.builder.control(''),
      d9: this.builder.control(''),
    }, { validators: this.monyTbgDawSelectionValidator });
    this.definitionForm.controls.monyGroup.setValue('None')
    this.definitionForm.controls.tbgGroup.setValue('None')
    this.dawCheckboxes = [
      { name: "0", checked: false },
      { name: "1", checked: false },
      { name: "2", checked: false },
      { name: "3", checked: false },
      { name: "4", checked: false },
      { name: "5", checked: false },
      { name: "6", checked: false },
      { name: "7", checked: false },
      { name: "8", checked: false },
      { name: "9", checked: false },
    ];
    this.dawCheckboxesOriginal = JSON.parse(JSON.stringify(this.dawCheckboxes))
  }

  //  DAW helpers
  get dawsHaveChanged(): boolean {
    return (JSON.stringify(this.dawCheckboxesOriginal) !=
            JSON.stringify(this.dawCheckboxes));
  }

  private setDawSelection(item: CheckboxItem): number {
    var idx = this.dawCheckboxes.findIndex(d => d.name == item.name);
    this.dawCheckboxes[idx] = item;
    return idx;
  }

  get selectedDaws(): string {
    var dawValues = this.dawCheckboxes.filter(dawc => {
      return (dawc.checked == true);
      }).map(d => d.name);
    console.log(dawValues.toString());
    return dawValues.toString();
  }

  private populateForm(): void {
    this.definition = this.data.definition

    if (this.editMode){
      var mony = this.definition.mony ?? 'None'
      var tbg = this.definition.tbg ?? 'None'

      this.definitionForm.controls.definitionTypeGroup.setValue(this.definition.definitionType)
      this.definitionForm.controls.monyGroup.setValue(mony)
      this.definitionForm.controls.tbgGroup.setValue(tbg)

      //  Split the CSV of DAWs from the DB and set the controls state.

      var splitDaw = this.definition.daw.split(',').map(String);

      splitDaw.forEach((d) => {
        var item: CheckboxItem = {
          name: d,
          checked: true
        }
        var idx = this.setDawSelection(item)
        this.definitionForm.controls[`d${item.name}`].setValue(item)
        })
    }
    this.definitionToEdit = this.definition
  }

  monyTbgDawSelectionValidator: ValidatorFn =  (control: AbstractControl): ValidationErrors | null => {
    if (!this.definitionForm) return;
    const mony = control.get('monyGroup').value ?? null
    const tbg = control.get('tbgGroup').value ?? null

    var result = mony && tbg &&
      ((mony === '' || mony ==='None') && (tbg === '' || tbg=='None') && !this.anyCheckboxChecked)
      ? { noMonyTbgOrDawSelection: true } : null;
      return result;
  }

  private checkFeatures(): void {
    for (const vwFeature of this.viewFeatures) {
      if (this.authorizationService.hasFeature(vwFeature.feature)) {
        vwFeature.accessible = true;
      }
    }
  }

  get anyCheckboxChecked(): boolean {
    var result =
      this.definitionForm.controls["d0"].value === true ||
      this.definitionForm.controls["d1"].value === true ||
      this.definitionForm.controls["d2"].value === true ||
      this.definitionForm.controls["d3"].value === true ||
      this.definitionForm.controls["d4"].value === true ||
      this.definitionForm.controls["d5"].value === true ||
      this.definitionForm.controls["d6"].value === true ||
      this.definitionForm.controls["d7"].value === true ||
      this.definitionForm.controls["d8"].value === true ||
      this.definitionForm.controls["d9"].value === true;

    return result;
  }

  public onCheckboxChange(event: MatCheckboxChange, item: any, index: number): void {
    this.dawCheckboxes[index].checked = event.checked
    this.saveDisabled =
       this.definitionForm.pristine ||
       this.definitionForm.invalid ||
      !this.haveValuesChanged(event);
  }

  public loadDefinition(): void {
    this.isLoadingResults = true;

    this.definitionService.getContractDefinitionForContractId(this.contractId, this.definitionId).subscribe(
      (result) => {
        this.definitionToEdit = result
        this.isLoadingResults = false
      },
      (err) => {
        toastr.error('We are unable to load the Definition at this time.');
        this.isLoadingResults = false;
      }
    )
  }

  checkSaveEnabled(event: MatRadioChange): void {
    this.saveDisabled =
       this.definitionForm.pristine ||
       this.definitionForm.invalid ||
      !this.haveValuesChanged(event);
  }

  haveValuesChanged(event: any): boolean {
      const definitionType = this.definitionForm.controls.definitionTypeGroup.value
      var mony = this.definitionForm.controls.monyGroup.value;
      var tbg = this.definitionForm.controls.tbgGroup.value;

      mony = (mony === '' || mony === 'None') ? '' : mony;
      tbg = (tbg === '' || tbg === 'None') ? '' : tbg;

      var valuesHaveChanged: boolean = (
        definitionType !== this.definitionToEdit.definitionType ||
        mony !== (this.definitionToEdit.mony ?? '') ||
        tbg !== (this.definitionToEdit.tbg ?? '') ||
        this.dawsHaveChanged
      );
      return valuesHaveChanged;
  }

  public saveDefinition() {
    this.isLoadingResults = true;

    var monySelection = this.definitionForm.controls.monyGroup.value;
    var tbgSelection = this.definitionForm.controls.tbgGroup.value;
    var selectedDaws = this.selectedDaws === "" ? null : this.selectedDaws;

    this.monyDisplay = monySelection;
    this.tbgDisplay = tbgSelection;

    monySelection = (monySelection === '' || monySelection === 'None') ? null : monySelection
    tbgSelection =  (tbgSelection === ''  || tbgSelection  === 'None') ? null : tbgSelection

    const contractDefinitionSaveRequestModel: any = {
      definitionId: null,
      definitionType: this.definitionForm.controls.definitionTypeGroup.value.trim(),
      mony: monySelection,
      tbg:  tbgSelection,
      daw: selectedDaws
    }

    if (this.editMode){
      contractDefinitionSaveRequestModel.definitionId = this.definitionToEdit.definitionId
      this.definitionService.updateContractDefinition(this.contractId, this.definitionId, contractDefinitionSaveRequestModel)
        .subscribe({
          next: (result) => {
              const returnData: AddEditDefinitionDialogDataModel = {
                action: 'close',
                clientId: this.clientId,
                contractId: this.contractId,
                definitionId: this.definitionToEdit.definitionId,
                definition: result
                }
                this.isLoadingResults = false
                this.dialogRef.close(returnData)
                toastr.success('Definition was updated successfully!')
              },
              error: (err) => {
                toastr.error('We were unable to update the Definition at this time.', err)
                this.isLoadingResults = false
              }
        })
    } else {
      this.definitionService.addContractDefinition(this.contractId, contractDefinitionSaveRequestModel)
        .subscribe({
          next: (result) => {
            const returnData: AddEditDefinitionDialogDataModel = {
              action: 'save',
              clientId: this.clientId,
              contractId: this.contractId,
              definitionId: result.definitionId,
              definition: result
            }
            this.isLoadingResults = false
            toastr.success('Definition was added successfully')
            this.OnSaveClick.emit(returnData)
            this.clearForm();
          },
          error: (err) => {
            toastr.error('We were unable to add the Definition at this time.', err)
            this.isLoadingResults = false
          }
        })
    }
  }

  ngAfterViewInit(): void {

  }
}
