<h3 mat-dialog-title class="inm-headline">&nbsp;&nbsp;{{pageTitle}}</h3>

<div mat-dialog-content>
  <div fxLayout="row wrap" fxLayout.sm="column" class="col-3" fxLayoutWrap fxLayoutGap="0.5%" fxLayoutAlign="left">
    <form [formGroup]="rateForm" [style.fontSize.px]="14" class="form-content">
      <div>
        <mat-form-field style="width: 33%;" appearance="outline" color="accent" fxFlexOffset="1">
          <mat-label>Rate Type</mat-label>
          <mat-select required *ngIf="isTraditionalRateType" (selectionChange)="checkSaveEnabled()"
            panelClass="selectbox-panel" title="Rate Type" formControlName="rateTypeCtrl" [compareWith]="compareObjectValues">
            <mat-option *ngFor="let rateType of contractRateTypeListTraditional" [value]="rateType.value">
              {{rateType.label}}
            </mat-option>
          </mat-select>
          <mat-select required *ngIf="!isTraditionalRateType" (selectionChange)="checkSaveEnabled()"
            panelClass="selectbox-panel" title="Rate Type" formControlName="rateTypeCtrl" [compareWith]="compareObjectValues">
            <mat-option *ngFor="let rateType of contractRateTypeListEffective" [value]="rateType.value">
              {{rateType.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field style="width: 33%;" appearance="outline" color="accent" fxFlexOffset="1">
          <mat-label>Price Type</mat-label>
          <mat-select required (selectionChange)="checkSaveEnabled()" title="Price Type" formControlName="priceTypeCtrl"
            [compareWith]="compareObjectValues">
            <mat-option *ngFor="let priceType of priceTypeCodesList" [value]="priceType.value">
              {{priceType.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field style="width: 33%;" appearance="outline" color="accent" fxFlexOffset="1">
          <mat-label>Days Supply</mat-label>
          <input (input)="checkSaveEnabled()" id="daysSupply" type="text" title="Days Supply" matInput
            formControlName="daysSupply" required maxlength="5" noWhitespace>
            <mat-icon matSuffix #tooltip="matTooltip" (click)="tooltip.toggle()" (mouseenter)="$event.stopImmediatePropagation()"
            (mouseleave)="$event.stopImmediatePropagation()" matTooltip="{{daysSupplyTooltipText}}" matTooltipPosition="before"  [matTooltipClass]="{'days-supply-tooltip': true}">
              <span matTooltipClass="{'days-supply-tooltip': true}">
                <svg class="inm-icon"><use xlink:href="#inm-icon__question-circle" /></svg>
                <i></i>
              </span>
            </mat-icon>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field style="width: 33%;" appearance="outline" color="accent" fxFlexOffset="1">
          <mat-label>Rate</mat-label>
          <input (ngModelChange)="checkSaveEnabled()" required type="text" 
            currencyMask [options]="{align: 'left',prefix: '',suffix: '%',nullable: true }"
            placeholder="Rate" title="Rate" matInput
            formControlName="appliedRateCtrl" maxlength="4" noWhitespace >
        </mat-form-field>
        <mat-form-field style="width: 33%;" appearance="outline" color="accent" fxFlexOffset="1">
          <mat-label>DIR Type</mat-label>
          <mat-select (selectionChange)="checkSaveEnabled()" formControlName="dirTypeCtrl"
            [compareWith]="compareObjectValues" title="DIR Type">
            <mat-option>None</mat-option>
            <mat-option *ngFor="let dirType of dirTypeList" [value]="dirType.value">
              {{dirType.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field style="width: 33%;" appearance="outline" color="accent" fxFlexOffset="1">
          <mat-label>DIR Amount</mat-label>
          <input (ngModelChange)="checkSaveEnabled()" type="text" currencyMask [options]="{align: 'left',nullable: true }"
            placeholder="DIR Amount" title="DIR Amount" matInput
            formControlName="dirAmountCtrl" maxlength="10" noWhitespace>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field style="width: 33%;" appearance="outline" color="accent" fxFlexOffset="1">
          <mat-label>Dispensing Fee</mat-label>
          <input (ngModelChange)="checkSaveEnabled()" required type="text" currencyMask [options]="{align: 'left',nullable: true }"
            title="Dispensing Fee" matInput
            formControlName="dispensingFeeCtrl">
        </mat-form-field>
        <mat-form-field style="width: 33%;" appearance="outline" color="accent" fxFlexOffset="1">
          <mat-label>Assessment Window</mat-label>
          <mat-select (selectionChange)="checkSaveEnabled()" title="Assessment Window" formControlName="assessmentWindowCtrl"
            [compareWith]="compareObjectValues">
            <mat-option>None</mat-option>
            <mat-option *ngFor="let assessmentWindow of assessmentWindowList" [value]="assessmentWindow.value">
              {{assessmentWindow.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field style="width: 33%;" appearance="outline" color="accent" fxFlexOffset="1">
          <mat-label>Effective Date Range</mat-label>
          <mat-date-range-input [rangePicker]="picker" required>
            <input (dateChange)="checkSaveEnabled()" matStartDate formControlName="rateEffectiveStartDateCtrl"
              placeholder="Effective Start">
            <input (dateChange)="checkSaveEnabled()" matEndDate formControlName="rateEffectiveEndDateCtrl"
              placeholder="Effective End">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
    </form>
  </div>
  <div>
    <div class="loading-shade" *ngIf="isLoadingResults">
      <mat-spinner color="accent" fxFlexOffset="50" fxFlexAlign="center" *ngIf="isLoadingResults">
      </mat-spinner>
    </div>
  </div>
  <div class="float-child" style="width: 100%;">
    <section class='inm-actions--confirmation'>
      <button class="inm-button--action button-spacing" [disabled]="saveDisabled || isLoadingResults"
        (click)="saveRate()">Save</button>
      <button class="inm-button button-spacing" [disabled]="isLoadingResults" [mat-dialog-close]="true">Cancel</button>
    </section>
  </div>
</div>
