import { DatePipe, SlicePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { AngularDualListBoxModule } from 'angular-dual-listbox';
import { CookieService } from 'ngx-cookie-service';
import {
  DropdownModule
} from 'primeng/dropdown';
import {
  InputTextModule
} from 'primeng/inputtext';
import {
  PanelModule
} from 'primeng/panel';
import {
  PasswordModule
} from 'primeng/password';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AdminSummaryComponent } from './components/admin/admin-summary/admin-summary.component';
import { ActivateContractDialogComponent } from './components/contracts/activate-contract-dialog/activate-contract-dialog/activate-contract-dialog.component';
import {
  AddEditDefinitionDialogComponent,
} from './components/contracts/add-edit-definition-dialog/add-edit-definition-dialog.component';
import { AddEditRateDialogComponent } from './components/contracts/add-edit-rate-dialog/add-edit-rate-dialog.component';
import { AmendContractComponent } from './components/contracts/amend-contract/amend-contract.component';
import { ConfirmDialogComponent } from './components/contracts/confirm-dialog/confirm-dialog/confirm-dialog.component';
import { ContractAmendmentDialogComponent } from './components/contracts/contract-amendment/contract-amendment-dialog/contract-amendment-dialog.component';
import { CreateEditDetailComponent } from './components/contracts/create-edit-detail/create-edit-detail.component';
import { CreateEditContractsComponent } from './components/contracts/create-edit/create-edit-contracts.component';
import { AddcontractfactorgpisComponent } from './components/contracts/create-factorGPI/addcontractfactorgpis/addcontractfactorgpis.component';
import { CreateContractnotesComponent } from './components/contracts/create-notes/create-contractnotes-component/create-contractnotes-component';
import { ManageContractComponent } from './components/contracts/manage/manage-contract.component';
import { ContractsComponent } from './components/contracts/search/contracts.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { HealthcheckComponent } from './components/healthcheck/healthcheck.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { LookerSideNavComponent } from './components/looker/looker-side-nav/looker-side-nav.component';
import { LookerSummaryComponent } from './components/looker/looker-summary/looker-summary.component';
import { LookerViewComponent } from './components/looker/looker-view/looker-view.component';
import { MyContractManagementComponent } from './components/my-contract-management/my-contract-management.component';
import { NoAccessComponent } from './components/no-access/no-access.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { AmendmentsGridComponent } from './components/shared/amendments-grid/amendments-grid.component';
import { ContractDetailsComponent } from './components/shared/contract-details/contract-details.component';
import { ContractfactorsComponent } from './components/shared/contract-factors/contractfactors/contractfactors.component';
import { FactorEntriesComponent } from './components/shared/contract-factors/factor-entries/factor-entries.component';
import { FactorsgpiGridComponent } from './components/shared/contract-factors/factorsgpi-grid/factorsgpi-grid.component';
import { ContractNotesComponent } from './components/shared/contract-notes-component/contract-notes-component';
import { NoteDialogComponent } from './components/shared/contract-notes-component/note-dialog/note-dialog.component';
import { DefinitionsGridComponent } from './components/shared/definitions-grid/definitions-grid.component';
import { DocumentsGridComponent } from './components/shared/documents-grid/documents-grid.component';
import { FileUploadComponent } from './components/shared/file-upload/file-upload.component';
import { MultipleCheckboxComponent } from './components/shared/multiple-checkbox/multiple-checkbox.component';
import { RatesGridComponent } from './components/shared/rates-grid/rates-grid.component';
import { TableToolbarComponent } from './components/shared/table-toolbar/table-toolbar.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import {
  AddContractFromTemplateDialogComponent,
} from './components/templates/add-contract-from-template-dialog/add-contract-from-template-dialog.component';
import {
  AddEditTemplateDetailsDialogComponent,
} from './components/templates/add-edit-template-details-dialog/add-edit-template-details-dialog.component';
import {
  AddEditTemplatesDialogComponent,
} from './components/templates/add-edit-templates-dialog/add-edit-templates-dialog.component';
import { ManageTemplatesComponent } from './components/templates/manage-templates/manage-templates.component';
import { TemplateLibraryComponent } from './components/templates/template-library/template-library.component';
import { TemplateSummaryComponent } from './components/templates/template-summary/template-summary.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { ViewContractPdfsComponent } from './components/view-contract-pdfs/view-contract-pdfs.component';
import { CustomRouteReuseStrategy } from './custom-route-reuse-strategy';
import { AdminModule } from './modules/admin/admin.module';
import { LookerModule } from './modules/looker/looker.module';
import { TemplateModule } from './modules/templates/template.module';
import * as routes from './routes';
import { AuthorizationService } from './services/authorization.service';
import { BaseService } from './services/baseService.service';
import { GlobalFilterService } from './services/global-filter.service';
import { TokenInterceptorService } from './token-interceptor.service';
import { NgxCurrencyModule } from "ngx-currency";

@NgModule({
  declarations: [
    AddContractFromTemplateDialogComponent,
    AddEditDefinitionDialogComponent,
    AddEditRateDialogComponent,
    AddEditTemplateDetailsDialogComponent,
    AddEditTemplatesDialogComponent,
    AdminSummaryComponent,
    AmendmentsGridComponent,
    AppComponent,
    ConfirmDialogComponent,
    ContractDetailsComponent,
    ContractNotesComponent,
    ContractsComponent,
    CreateContractnotesComponent,
    CreateEditContractsComponent,
    DefinitionsGridComponent,
    FooterComponent,
    HeaderComponent,
    HealthcheckComponent,
    ManageContractComponent,
    ManageTemplatesComponent,
    MyContractManagementComponent,
    NoAccessComponent,
    NoteDialogComponent,
    PageNotFoundComponent,
    RatesGridComponent,
    SideNavComponent,
    TableToolbarComponent,
    TemplateLibraryComponent,
    TemplateSummaryComponent,
    ToolbarComponent,
    ViewContractPdfsComponent,
    DocumentsGridComponent,
    FileUploadComponent,
    FactorEntriesComponent,
    FactorsgpiGridComponent,
    ContractfactorsComponent,
    AddcontractfactorgpisComponent,
    CreateEditDetailComponent,
    ContractAmendmentDialogComponent,
    ActivateContractDialogComponent,
    MultipleCheckboxComponent,
    AmendContractComponent,
    LandingPageComponent,
    LookerSummaryComponent,
    LookerViewComponent,
    LookerSideNavComponent
  ],
  imports: [
    AdminModule,
    MatProgressBarModule,
    AngularDualListBoxModule,
    BrowserAnimationsModule,
    BrowserModule,
    DropdownModule,
    FlexLayoutModule,
    FormsModule,
    HttpClientModule,
    InputTextModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatNativeDateModule,
    MatOptionModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    PanelModule,
    PasswordModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes.appRoutes, {
      enableTracing: !environment.production,
      onSameUrlNavigation: 'reload',
      useHash: true
    }),
    TemplateModule,
    LookerModule,
    NgxCurrencyModule
  ],
  providers: [
    AuthorizationService,
    GlobalFilterService,
    BaseService,
    {
      provide: MatDialogRef,
      useValue: {}
    },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy,
    },
    DatePipe,
    SlicePipe
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
