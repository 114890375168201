import { DatePipe } from '@angular/common'
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators, isFormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { map } from 'lodash';
import { ContractByIdResponse } from 'src/app/models/ContractByIdResponse';
import { SelectItem } from 'src/app/models/SelectItem';
import { ContractService } from 'src/app/services/contractService';
import { ContractStatusService } from 'src/app/services/contractStatusService';
import { ContractClassificationService } from 'src/app/services/contractClassificationService';
import { ContractTypeService } from 'src/app/services/contractTypeService';
import { PayerService } from 'src/app/services/payerService';

import { ContractCreateEditDialogData } from './ContractCreateEditDialogDataModel';

declare var require: any

@Component({
  selector: 'app-create-edit-contracts',
  templateUrl: './create-edit-contracts.component.html',
  styleUrls: ['./create-edit-contracts.component.scss']
})
export class CreateEditContractsComponent implements OnInit, AfterViewInit {

  contractForm: UntypedFormGroup;
  contractToEdit: ContractByIdResponse;

  saveDisabled = true;
  editMode = true;
  addMode = true;
  pagetitle: string;
  isLoadingResults = false;
  numRegex = /^-?\d*[.,]?\d{0,2}$/;

  payersList: SelectItem[];
  filteredPayers: SelectItem[];
  contractTypeList: SelectItem[];
  contractTypes: SelectItem[];
  paymentTermsList: SelectItem[];
  paymentTerms: SelectItem[];
  paymentTermsOptions: SelectItem[];
  contractStatusList: SelectItem[] = [];
  contractStatuses: SelectItem[] = [];
  contractClassificationList: SelectItem[] = [];

  contractClassification: number;

  daysSupply: number;

  dataSource: any;

  contractId: number;
  clientId: number;
  mode: string;

  constructor(
    private fb: UntypedFormBuilder,
    private contractTypeService: ContractTypeService,
    private contractClassificationService: ContractClassificationService,
    private contractStatusService: ContractStatusService,
    private contractService: ContractService,
    private payerService: PayerService,
    public dialogRef: MatDialogRef<CreateEditContractsComponent>,
    public datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: ContractCreateEditDialogData) {

  }

  ngOnInit(): void {
    this.loadAllPayers();
    this.loadAllContractTypes();
    this.loadAllContractClassifications();
    this.loadAllPaymentTerms();
    this.loadAllTrueUpValues();
    this.loadAllContractStatuses();
    this.initValidators();

    this.contractId = this.data.contractId;
    this.clientId = this.data.clientId;
    this.mode = this.data.action;
    this.pagetitle = 'Contract Entry';


    if (this.mode === "edit") {
      this.editMode = true;
      this.addMode = false;
      this.pagetitle = 'Edit Contract';
    }

    if (this.mode === "add") {
      this.editMode = false;
      this.addMode = true;
      this.pagetitle = 'Add Contract';
    }

    if (this.editMode) {
      this.loadContract();
    }

    if (this.addMode) {
      this.loadEmptyContract();
    }
  }

  ngAfterViewInit(): void {

  }

  initValidators(): void {
    this.contractForm = this.fb.group({
      contractId: [''],
      payerGroup: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(100)
        ])
      ],
      contractTypes: ['', Validators.compose([Validators.required])],
      daysSupply: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.min(1),
          Validators.max(365)
        ])
      ],
      // made null and not ' ' here due to how the ngx-currency handles ' '
      // null allows the input box to start empty where as ' ' pre-fills the input as $0.00
      transactionFee: [
        null,
        Validators.compose([
          Validators.min(0),
          Validators.pattern(this.numRegex)
        ])
      ],
      paymentTermDays: [
        '',
        Validators.compose([
          Validators.required,
          Validators.min(1),
          Validators.max(1825)
        ])
      ],
      effectiveStartDate: ['', Validators.compose([Validators.required])],
      effectiveEndDate: [''],
      networkName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(50)
        ]),
      ],
      industryContractTemplateId: [
        ''
      ],
      contractStatusId: [''],
      trueUp: [''],
      trueUpDate: [''],
      contractClassificationId: ['']
    });
  }

  loadAllPayers(): void {
    this.isLoadingResults = true;
    this.payerService.getPayerList().subscribe({
      next: (result) => {
        this.payersList = this.transformValue(result);
        this.filteredPayers = this.payersList;
        this.isLoadingResults = false;
      },
      error: (err) => {
        toastr.error('We are unable to load Payers at this time');
        this.isLoadingResults = false;
      },
    });
  }

  loadAllContractTypes(): void {
    this.contractTypes = this.contractTypeService.types;
  }

  days: number[] = [30, 60, 90];
  paymentTermsArray: SelectItem[] = [];

  loadAllPaymentTerms(): void {
    this.paymentTermsList = this.transformValue(this.days);
  }

  trueUpValues: string[] = ['Quarterly', 'Trimester', 'Bi-Annually', 'Yearly'];
  trueUpValuesList: SelectItem[] = [];

  loadAllTrueUpValues(): void {
    this.trueUpValuesList = this.transformValue(this.trueUpValues);
  }

  loadAllContractClassifications(): void {
    this.isLoadingResults = true;
    this.contractClassificationService.getContractClassificationList().subscribe({
      next: (result) => {
        this.contractClassificationList = result.contractClassificationList.map((a) => ({
          value: a.contractClassificationId,
          label: a.name,
        }));
        this.isLoadingResults = false;
      },
      error: (err) => {
        toastr.error('We are unable to load Contract Classifications at this time');
        this.isLoadingResults = false;
      },
    });
  }

  arrayObjects: SelectItem[] = [];

  transformValue(response): SelectItem[] {
    this.arrayObjects = [];
    response.forEach(data => {
      this.arrayObjects.push({
        label: data,
        value: data
      })
    })
    return this.arrayObjects;
  }

  loadAllContractStatuses() {
    this.contractStatuses = this.contractStatusService.statuses;
  }

  trueUpValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const moment = require('moment');
    const trueUpValue = !control.get('trueUp').value ? null : !control.get('trueUp').value.value ? control.get('trueUp').value : control.get('trueUp').value.value;

    var strTrueUpDate = control.get('trueUpDate').value ? moment(control.get('trueUpDate').value).format('YYYY-MM-DD') : null;
    if (strTrueUpDate == "Invalid date") {
      strTrueUpDate = null;
    }
    // if the true up field has a value, then we must have a value for the true up date
    if (trueUpValue != null && trueUpValue != undefined && trueUpValue.length > 0) {
      if (strTrueUpDate == null || strTrueUpDate == undefined || strTrueUpDate.length < 1) {
        return { trueUpDateMissing: true };
      }
    }

    // if we do not have a true up value, then we cannot have a true up date
    if (trueUpValue == null || trueUpValue == undefined || trueUpValue.length < 1) {
      if (strTrueUpDate != null && strTrueUpDate != undefined && strTrueUpDate.length > 0) {
        return { trueUpValueMissing: true };
      }
    }
    return null;
  };


  loadEmptyContract(): void {
    this.contractForm = this.fb.group({
      contractId: [''],
      payerGroup: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      contractTypes: ['', Validators.compose([Validators.required])],
      daysSupply: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.min(1),
          Validators.max(365)
        ])
      ],
      // made null and not ' ' here due to how the ngx-currency handles ' '
      // null allows the input box to start empty where as ' ' pre-fills the input as $0.00
      transactionFee: [
        null,
        Validators.compose([
          Validators.min(0),
          Validators.pattern(this.numRegex)
        ])
      ],
      paymentTermDays: [
        '',
        Validators.compose([
          Validators.required,
          Validators.min(1),
          Validators.max(1825)
        ])
      ],
      effectiveStartDate: ['', Validators.compose([Validators.required])],
      effectiveEndDate: [''],
      networkName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(50),
          Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)
        ]),
      ],
      industryContractTemplateId: [
        ''
      ],
      contractStatusId: [''],
      trueUp: [''],
      trueUpDate: [''],
      contractClassificationId: ['']
    });
  }

  loadContract(): void {
    this.isLoadingResults = true;
    this.contractService.getContract(this.contractId).subscribe(
      (result) => {
        // change dates that are YYYY-MM-DD format to YYYY/MM/DD format
        const moment = require('moment');
        var strEffectiveStartDate = moment(result.effectiveStartDate).format('YYYY/MM/DD');
        var strEffectiveEndDate = result.effectiveEndDate ? moment(result.effectiveEndDate).format('YYYY/MM/DD') : null;
        var strTrueUpDate = moment(result.trueUpDate).format('YYYY/MM/DD');

        var currentEffectiveStartDate = new Date(strEffectiveStartDate);
        var currentEffectiveEndDate = strEffectiveEndDate != null && strEffectiveEndDate != "Invalid date" ? new Date(strEffectiveEndDate) : null;
        var currentTrueUpDate = strTrueUpDate != null && strTrueUpDate != "Invalid date" ? new Date(strTrueUpDate) : null;

        this.contractForm = this.fb.group({
          contractId: [result.contractId, Validators.compose([Validators.required])],
          payerGroup: [
            result.payerGroupName,
            Validators.compose([
              Validators.required
            ])
          ],
          contractTypes: [result.contractContractTypes, Validators.compose([Validators.required])],
          daysSupply: [
            result.daysSupply,
            Validators.compose([
              Validators.required,
              Validators.pattern("^[0-9]*$"),
              Validators.min(1),
              Validators.max(365)
            ])
          ],
          transactionFee: [
            result.adminOrTransactionFee,
            Validators.compose([
              Validators.min(0),
              Validators.pattern(this.numRegex)
            ])
          ],
          paymentTermDays: [
            result.paymentTermDays,
            Validators.compose([
              Validators.required,
              Validators.min(1),
              Validators.max(1825)
            ])
          ],
          effectiveStartDate: [currentEffectiveStartDate, Validators.compose([Validators.required])],
          effectiveEndDate: [currentEffectiveEndDate],
          networkName: [
            result.networkName,
            Validators.compose([
              Validators.required,
              Validators.maxLength(50),
              Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)
            ]),
          ],
          industryContractTemplateId: [
            result.industryContractTemplateId
          ],
          contractStatusId: [
            result.contractStatusId
          ],
          trueUp: [result.trueUp],
          trueUpDate: [currentTrueUpDate],
          contractClassificationId: [result.contractClassificationId]
        });

        this.daysSupply = result.daysSupply;
        this.contractToEdit = result;
        this.isLoadingResults = false;
      },
      (err) => {
        toastr.error('We are unable to load Contracts at this time');
        this.isLoadingResults = false;
      }
    )
  }

  onKeyPayer(value): void {
    const filterValue = value.toLowerCase();
    this.filteredPayers = this.payersList.filter((payer) =>
      payer.label.toLowerCase().includes(filterValue)
    );
  }

  onKeyPaymentTermDays(option): void {
    this.contractForm.controls.paymentTermDays.setValue(option.value.value);
  }

  compareObjectValues(o1: any, o2: any): boolean {
    return o1.value === o2;
  }

  compareObjectLabels(o1: any, o2: any): boolean {
    return o1.label === o2;
  }

  comparePaymentTermValues(o1: any, o2: any): boolean {
    return o1.value === o2;
  }

  saveContract() {
    this.saveDisabled = true;
    const moment = require('moment');
    var strEffectiveStartDate = moment(this.contractForm.controls.effectiveStartDate.value).format('YYYY-MM-DD');
    var strEffectiveEndDate = this.contractForm.controls.effectiveEndDate.value ? moment(this.contractForm.controls.effectiveEndDate.value).format('YYYY-MM-DD') : null;
    var strTrueUpDate = this.contractForm.controls.trueUpDate.value ? moment(this.contractForm.controls.trueUpDate.value).format('YYYY-MM-DD') : null;
    var contractTypeIdList = this.contractForm.controls.contractTypes.value ? map(this.contractForm.controls.contractTypes.value, 'value') : undefined

    if (contractTypeIdList != undefined && contractTypeIdList != null) {
      for (let typeId of contractTypeIdList) {
        if (typeId == undefined) {
          contractTypeIdList = this.contractForm.controls.contractTypes.value;
          break;
        }
      }
    }

    var localContractStatusId = this.contractForm.controls.contractStatusId.value.value ? this.contractForm.controls.contractStatusId.value.value : this.contractForm.controls.contractStatusId.value;
    var trueUpValue = !this.contractForm.controls.trueUp.value ? null : !this.contractForm.controls.trueUp.value.value ? this.contractForm.controls.trueUp.value : this.contractForm.controls.trueUp.value.value;
    var contractClassificationValue = !this.contractForm.controls.contractClassificationId.value ? null : 
                                        !this.contractForm.controls.contractClassificationId.value.value ? this.contractForm.controls.contractClassificationId.value :
                                        this.contractForm.controls.contractClassificationId.value.value;

    const contractSaveRequestModel: any = {
      networkName: this.contractForm.controls.networkName.value.trim(),
      payerGroupName: this.contractForm.controls.payerGroup.value.value ? this.contractForm.controls.payerGroup.value.value : this.contractForm.controls.payerGroup.value,
      daysSupply: this.contractForm.controls.daysSupply.value,
      adminOrTransactionFee: this.contractForm.controls.transactionFee.value === "" ? null : this.contractForm.controls.transactionFee.value,
      paymentTermDays: this.contractForm.controls.paymentTermDays.value.value ? this.contractForm.controls.paymentTermDays.value.value : this.contractForm.controls.paymentTermDays.value,
      effectiveStartDate: strEffectiveStartDate,
      effectiveEndDate: strEffectiveEndDate,
      contractTypeIds: contractTypeIdList == undefined ? this.contractForm.controls.contractTypes.value : contractTypeIdList,
      contractStatusId: localContractStatusId == undefined || localContractStatusId == null || localContractStatusId.length < 1 ? 1 : localContractStatusId,
      trueUpDate: strTrueUpDate,
      trueUp: trueUpValue,
      contractClassificationId: contractClassificationValue
    };

    if (this.editMode) {
      this.isLoadingResults = true;
      this.contractService.updateContractById(contractSaveRequestModel, this.contractToEdit.contractId)
        .subscribe({
          next: (result) => {

            const returnData: ContractCreateEditDialogData = {
              action: 'close',
              contractId: this.contractToEdit.contractId,
              clientId: this.data.clientId,
              contract: result
            }
            this.isLoadingResults = false;
            this.dialogRef.close(returnData);
            toastr.success('Contract was updated successfully!');
          },
          error: (err) => {
            toastr.error('We are unable to update the contract at this time.', err);
            this.isLoadingResults = false;
          }
        });
    } else {
      this.isLoadingResults = true;
      this.contractService.addContract(contractSaveRequestModel)
        .subscribe({
          next: (result) => {

            const returnData: ContractCreateEditDialogData = {
              action: 'close',
              contractId: result.contractId,
              clientId: result.clientId,
              contract: null
            }
            this.isLoadingResults = false;
            this.dialogRef.close(returnData);
            this.saveDisabled = false;
            toastr.success('Contract was added successfully!');
          },
          error: (err) => {
            toastr.error('We are unable to add the contract at this time.', err);
            this.isLoadingResults = false;
            this.saveDisabled = false;
          }
        });
    }
  }

  /**
   * Check if save is enabled
   */
  checkSaveEnabled(): void {
    this.saveDisabled =
      this.contractForm.pristine ||
      this.contractForm.invalid ||
      this.checkValuesChange();
  }

  checkValuesChange(): boolean {
    if (this.editMode) {
      const networkName = this.contractForm.controls.networkName.value;
      const payerGroup = this.contractForm.controls.payerGroup.value.value == undefined || this.contractForm.controls.payerGroup.value.value == null ? this.contractForm.controls.payerGroup.value : this.contractForm.controls.payerGroup.value.value;
      const daysSupply = this.contractForm.controls.daysSupply.value;
      const contractTypeIds = this.contractForm.controls.contractTypes.value ? map(this.contractForm.controls.contractTypes.value, 'value') : [];
      const transactionFee = this.contractForm.controls.transactionFee.value;
      const paymentTermDays = this.contractForm.controls.paymentTermDays.value.value == undefined || this.contractForm.controls.paymentTermDays.value.value == null ? this.contractForm.controls.paymentTermDays.value : this.contractForm.controls.paymentTermDays.value.value;
      const effectiveStartDate = this.contractForm.controls.effectiveStartDate.value ? this.datePipe.transform(this.contractForm.controls.effectiveStartDate.value, 'yyyy-MM-dd') : "";
      const effectiveEndDate = this.contractForm.controls.effectiveEndDate.value ? this.datePipe.transform(this.contractForm.controls.effectiveEndDate.value, 'yyyy-MM-dd') : "";
      const trueUp = !this.contractForm.controls.trueUp.value ? null : !this.contractForm.controls.trueUp.value.value ? this.contractForm.controls.trueUp.value : this.contractForm.controls.trueUp.value.value;
      const trueUpDate = this.contractForm.controls.trueUpDate.value ? this.datePipe.transform(this.contractForm.controls.trueUpDate.value, 'yyyy-MM-dd') : "";
      const contractClassificationId = !this.contractForm.controls.contractClassificationId.value ? null : 
        !this.contractForm.controls.contractClassificationId.value.value ? this.contractForm.controls.contractClassificationId.value :
        this.contractForm.controls.contractClassificationId.value.value;

      var valuesHaveChanged: boolean = (
        networkName === this.contractToEdit.networkName &&
        payerGroup === this.contractToEdit.payerGroupName &&
        daysSupply === this.contractToEdit.daysSupply &&
        transactionFee === this.contractToEdit.adminOrTransactionFee &&
        paymentTermDays === this.contractToEdit.paymentTermDays &&
        effectiveStartDate === (this.contractToEdit.effectiveStartDate ? this.datePipe.transform(this.contractToEdit.effectiveStartDate, 'yyyy-MM-dd') : "") &&
        effectiveEndDate === (this.contractToEdit.effectiveEndDate ? this.datePipe.transform(this.contractToEdit.effectiveEndDate, 'yyyy-MM-dd') : "") &&
        trueUp === this.contractToEdit.trueUp &&
        trueUpDate === (this.contractToEdit.trueUpDate ? this.datePipe.transform(this.contractToEdit.trueUpDate, 'yyyy-MM-dd') : "") &&
        contractClassificationId === this.contractToEdit.contractClassificationId &&
        this.arraysAreEqual(contractTypeIds, this.contractToEdit.contractContractTypes.map(obj => obj))
      );
      return valuesHaveChanged;
    }
    return false;
  }

  arraysAreEqual(array1: any[], array2: any[]): boolean {

    if (array1.length !== array2.length) {
      return false;
    }
    if (array1.every((value) => value === undefined)) {
      return true;
    }
    array2.sort((a, b) => a - b);
    array1.sort((a, b) => a - b);
    var equalArray: boolean = array1.every((value, index) => value === array2[index]);
    return equalArray;
  }
}
