import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortHeader, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { map } from 'lodash';
import { ContractContractType } from 'src/app/models/ContractContractType';
import { ContractListResponse } from 'src/app/models/ContractListResponse';
import { ContractPdf } from 'src/app/models/ContractPdf';
import { ContractPdfDetail } from 'src/app/models/ContractPdfDetail';
import { ContractSearchResponse } from 'src/app/models/ContractSearchResponse';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { ContractService } from 'src/app/services/contractService';
import { ContractStatusService } from 'src/app/services/contractStatusService';
import * as toastr from 'toastr';

import { Pagination } from '../../../models/Pagination';
import { SelectItem } from '../../../models/SelectItem';
import { ContractTypeService } from '../../../services/contractTypeService';
import { GlobalFilterService } from '../../../services/global-filter.service';
import { PayerService } from '../../../services/payerService';
import { ToolbarAction } from '../../shared/table-toolbar/ToolBarAction';
import { ViewContractPdfsComponent } from '../../view-contract-pdfs/view-contract-pdfs.component';
import { ContractViewToolbarHelper } from '.././search/helper/contract-view-toolbar.helper';
import { ContractCreateEditDialogData } from '../create-edit/ContractCreateEditDialogDataModel';
import { CreateEditContractsComponent } from '../create-edit/create-edit-contracts.component';

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.scss']
})
export class ContractsComponent implements OnInit{

  @HostListener('window:keyup.enter')
  onKeyPressEnter() {
    this.onSubmit();
  }

  constructor(
    private payerService: PayerService,
    private contractTypeService: ContractTypeService,
    private contractStatusService: ContractStatusService,
    private contractService: ContractService,
    private globalFilterService: GlobalFilterService,
    private authorizationService: AuthorizationService,
    private router: Router,
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
  ) {
    globalFilterService.ClientChanged.subscribe(() => {
      this.onResetFilter();
      this.ngOnInit();
    });
    this.toolbarHelper = new ContractViewToolbarHelper(this.authorizationService);
    this.checkFeatures();
  }


  isLoadingResults = false;
  routeActivated = false;
  showSearchResults = false;
  showNewPbidColumn = false;
  isGridEmpty = true;

  // Data sources
  dataSource: any;
  totalItemCount: number;

  payersList: SelectItem[];
  filteredPayers: SelectItem[];
  selectedPayer: string;

  contractTypeList: SelectItem[];
  contractTypes: SelectItem[];
  localContractTypes: SelectItem[] = [];
  contractTypeResponseList: ContractContractType[] = [];
  networkName: string;
  enterednetworkName: string;
  selectedContractTypes: number[];

  contractStatusList: SelectItem[];
  contractStatuses: SelectItem[];
  localContractStatusList: SelectItem[] = [];
  filteredContractStatuses: SelectItem[];
  selectedContractStatus: number[];

  createdDateFrom: Date;
  createdDateTo: Date;

  // Filter Form Related stuff
  filterFormGroup: UntypedFormGroup;
  exceptionFilter: number;
  resetEnabled = false;
  selectShow = false;
  ResolveDisable = true;
  datepipe: DatePipe = new DatePipe('en-US');

  sort = 'NetworkName';
  sortOrder = 'asc';
  offset = 0;
  limit = 30;
  pageIndex = 0;

  hasNoData = true;

  contractSearchResponse: ContractSearchResponse;
  elementData: ContractListResponse[] = [];
  filteredData: ContractListResponse[] = [];
  validData: ContractListResponse[] = [];
  typeData: ContractContractType[] = [];
  pdfData: ContractPdf[] = [];

  selection = new SelectionModel<ContractListResponse>(true, []);

  public readonly toolbarHelper: ContractViewToolbarHelper;

  displayedColumns: string[] = [
    'NetworkName',
    'PayerGroupName',
    'ContractType',
    'ContractId',
    'CreatedDate',
    'CreatedBy',
    'LastModifiedDate',
    'LastModifiedBy',
    'ContractStatus',
    'EffectiveStartDate',
    'LinksToContractPdfs'
  ];

  public readonly viewFeatures = [
    { feature: 'UI::DeleteContract', name: 'delete', accessable: false },
    { feature: 'ContractManagement::ViewContract', name: 'manage', accessable: false },
    { feature: 'ContractManagement::AddEditContract', name: 'add', accessable: false }
  ];

  @ViewChild(MatSort) Sort: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('matInputPayerSearch') inputPayerSearch: ElementRef;
  @ViewChild('matInputContractStatusSearch') inputContractStatusSearch: ElementRef;

  onSubmit(): void {
    this.resetEnabled = true;
    this.pageIndex = 0;
    this.offset = 0;
    this.showSearchResults = true;

    // get the values and submit the search query
    this.enterednetworkName = this.filterFormGroup.controls.networkNameCtrl.value;

    this.selectedPayer = "";
    if (this.filterFormGroup.controls.payerIdCtrl.value != undefined && this.filterFormGroup.controls.payerIdCtrl.value != null) {
      this.selectedPayer = this.filterFormGroup.controls.payerIdCtrl.value.value;
    }

    this.selectedContractTypes = this.filterFormGroup.controls.contractTypesCtrl.value ?
      map(this.filterFormGroup.controls.contractTypesCtrl.value, 'value')
      : [];

    this.createdDateFrom = this.filterFormGroup.controls.createdDateFrom.value != "" ? this.filterFormGroup.controls.createdDateFrom.value : null;
    this.createdDateTo = this.filterFormGroup.controls.createdDateTo.value != "" ? this.filterFormGroup.controls.createdDateTo.value : null;

    this.selectedContractStatus = this.filterFormGroup.controls.contractStatusCtrl.value ?
    map(this.filterFormGroup.controls.contractStatusCtrl.value, 'value')
    : [];

    this.loadAllContracts();
  }

  loadAllContracts(): void {
    this.loadAllLocalContractTypes();
    this.loadAllLocalContractStatuses();
    this.isLoadingResults = true;
    this.contractService.getContracts(
      new Pagination(this.limit, this.offset, this.sort, this.sortOrder),
      this.enterednetworkName,
      this.selectedContractTypes,
      this.selectedContractStatus,
      this.createdDateFrom,
      this.createdDateTo,
      this.selectedPayer
    )
      .subscribe(
        (result) => {
          // Pagination stuff.
          this.limit = result.limit;
          this.offset = result.offset;
          this.totalItemCount = result.totalItemCount;
          this.selection.clear();

          // Data stuff
          this.contractSearchResponse = result;
          this.elementData = this.contractSearchResponse.items;
          var localContractTypesList = this.localContractTypes;
          var localContractStatusListB = this.localContractStatusList;

          if (this.elementData != null) {
            this.elementData.forEach(function (part, index) {
              this.contractTypeResponseList = this[index].contractContractTypes;
              if (this.contractTypeResponseList != null) {
                this.contractTypeResponseList.forEach(function (part2, index2) {
                  if (part2.contractTypeId != null && localContractTypesList) {
                    localContractTypesList.forEach(function (part3, index3) {
                      if (part3.value == part2.contractTypeId) {
                        part2.contractTypeDesc = part3.label;
                      }
                    });

                  }
                }, this.contractTypeResponseList);
              }

              if (part.contractStatusId != null && localContractStatusListB) {
                localContractStatusListB.forEach(function (part4, index4) {
                  if (part4.value == part.contractStatusId) {
                    part.contractStatusDesc = part4.label;
                  }
                });
              }
            }, this.elementData);
          }
          this.filteredData = this.elementData;
          this.dataSource = new MatTableDataSource<ContractListResponse>(
            this.filteredData
          );

          this.hasNoData = this.dataSource == null || this.dataSource == undefined || this.dataSource.data.length < 1;

          this.isLoadingResults = false;
          this.resetEnabled = true;
        },
        (err) => {
          toastr.error('We are unable to load Contracts at this time');
          this.isLoadingResults = false;
          this.resetEnabled = true;
        }
      );
  }

  sortData(sort: Sort): void {
    this.sort = sort.active;
    this.sortOrder = sort.direction;
    this.paginator.firstPage();
    this.loadAllContracts();
  }

  ngOnInit(): void {
    this.initFormGroup();
    this.loadAllPayers();
    this.loadAllContractTypes();
    this.loadAllContractStatuses();
    this.onChanges();
  }


  loadAllPayers(): void {
    this.isLoadingResults = true;
    this.payerService.getPayerList().subscribe({
      next: (result) => {
        this.payersList = this.transformValue(result);
        this.filteredPayers = this.payersList;
        this.isLoadingResults = false;
      },
      error: (err) => {
        toastr.error('We are unable to load Payers at this time');
        this.isLoadingResults = false;
      },
    });
  }

  loadAllContractTypes(): void {
    this.isLoadingResults = true;
    this.contractTypeService.getContractTypeList().subscribe({
      next: (result) => {
        this.contractTypeList = result.contractTypeList.map((a) => ({
          value: a.contractTypeId,
          label: a.name,
        }));
        this.contractTypes = this.contractTypeList;
        this.isLoadingResults = false;
        this.contractTypeService.types = this.contractTypes;
      },
      error: (err) => {
        toastr.error('We are unable to load Contract Types at this time');
        this.isLoadingResults = false;
      },
    });
    this.loadAllLocalContractTypes();
  }

  loadAllLocalContractTypes(): void {
    this.localContractTypes = this.contractTypeService.types;
  }

  loadAllContractStatuses(): void {
    this.isLoadingResults = true;
    this.contractStatusService.getContractStatusList().subscribe({
      next: (result) => {
        this.contractStatusList = this.transformStatusValues(result);
        this.contractStatuses = this.contractStatusList;
        this.isLoadingResults = false;
        this.contractStatusService.statuses = this.contractStatuses;

        this.loadInitialData();
      },
      error: (err) => {
        toastr.error('We are unable to load Contract Statuses at this time');
        this.isLoadingResults = false;
      }
    });

    this.loadAllLocalContractStatuses();
  }

  loadInitialData() {
    this.patchContractStatusCtrlValue();
    this.onSubmit();
    const sortState: Sort = {active: 'NetworkName', direction: 'asc'};
    this.Sort.active = sortState.active;
    this.Sort.direction = sortState.direction;
    this.Sort.sortChange.emit(sortState);
  }

  patchContractStatusCtrlValue() {
    // [1, 3] 1 -> Active and 3 -> pending
    var preselectStatus = this.contractStatusList.filter(x => [1,3].includes(x.value))
    this.filterFormGroup.controls.contractStatusCtrl.patchValue(preselectStatus)
  }

  loadAllLocalContractStatuses() {
    this.localContractStatusList = this.contractStatusService.statuses;
  }


  arrayObjects: SelectItem[] = [];
  transformValue(response): SelectItem[] {
    this.arrayObjects = [];
    response.forEach(data => {
      this.arrayObjects.push({
        label: data,
        value: data
      })
    })
    return this.arrayObjects;
  }

  statusArrayObjects: SelectItem[] = [];
  transformStatusValues(response): SelectItem[] {
    this.statusArrayObjects = [];
    response.forEach(data => {
      this.statusArrayObjects.push({
        label: data.name,
        value: data.contractStatusId
      })
    });

    return this.statusArrayObjects;
  }

  onKeyPayer(value): void {
    const filterValue = value.toLowerCase();
    this.filteredPayers = this.payersList.filter((payer) =>
      payer.label.toLowerCase().includes(filterValue)
    );
  }

  onKeyContractStatus(value): void {
    const filterValue = value.toLowerCase();
    this.filteredContractStatuses = this.contractStatusList.filter((contractStatus) =>
      contractStatus.label.toLowerCase().includes(filterValue)
    );
  }

  onChanges(): void {
    this.filterFormGroup.valueChanges.subscribe(() => {
      this.isGridEmpty = true;
    });
  }


  onPageChange(event: PageEvent): void {
    this.limit = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.offset = event.pageIndex * event.pageSize;
    this.selection.clear();

    this.loadAllContracts();
  }

  initFormGroup(): void {
    this.filterFormGroup = this.fb.group(
      {
        networkNameCtrl: '',
        payerIdCtrl: '',
        contractTypesCtrl: '',
        contractStatusCtrl: '',
        createdDateFrom: '',
        createdDateTo: '',
      }
    );
  }

  onResetFilter(): void {
    this.filterFormGroup.reset();
    this.filterFormGroup.markAsUntouched();
    this.filterFormGroup.markAsPristine();
    this.resetEnabled = false;
    this.showSearchResults = false;
    this.isGridEmpty = true;
    this.sort = 'NetworkName';
    this.sortOrder = 'asc';
    this.pageIndex = 0;
    this.offset = 0;
    this.limit = 30;
    this.dataSource = [];
    this.inputPayerSearch.nativeElement.value = '';
    this.filteredPayers = this.payersList;
    this.contractTypes = this.contractTypeList;
    this.contractStatuses = this.contractStatusList;

    this.loadInitialData();
  }

  clickPdfs(element: ContractListResponse) {
    this.viewContractPdfData(element);
  }


  contractPdfsList: ContractPdfDetail[];
  contractPdfs: ContractPdf[];
  viewContractPdfData(contractListObj: ContractListResponse): void {
    var listSize = contractListObj.contractPdfs.length * 200;

    if (listSize > 1000) {
      listSize = 1000;
    }

    this.contractPdfsList = contractListObj.contractPdfs;
    this.contractPdfsList.sort((a, b) => a.contractFileName.localeCompare(b.contractFileName));


    const dialogRef = this.dialog.open(ViewContractPdfsComponent, {
      width: '1000px',
      maxHeight: '700px',
      data: {
        action: 'view',
        title: 'Contract PDFs',
        contractId: contractListObj.contractId,
        clientId: contractListObj.clientId,
        contractPDFs: new MatTableDataSource<ContractPdfDetail>(this.contractPdfsList)
      },
    });
    dialogRef.afterClosed().subscribe((choice) => {
      if (choice === 'yes') {
        console.log('test');
      }
    });
  }

  toolbarBtnClick(event: ToolbarAction): void {
    switch (event.name) {
      case 'addContract': {
        const dialogRef = this.dialog.open(CreateEditContractsComponent, {
          width: '1500px',
          maxWidth: '95vw',
          maxHeight: '800px',
          data: {
            action: 'add',
            contractId: null,
            clientId: this.globalFilterService.selectedClientId
          },
        });
        dialogRef.afterClosed().subscribe((returnedData: ContractCreateEditDialogData) => {
          if (returnedData.action === 'close') {
            this.router.navigate(['cm/contracts/contractview/', returnedData.contractId, 'view'])
          }
        })

        break;
      }
    }
  }

  private checkFeatures(): void {
    for (const vwFeature of this.viewFeatures) {
      if (this.authorizationService.hasFeature(vwFeature.feature)) {
        vwFeature.accessable = true;
      }
    }
  }

}
