<h3 mat-dialog-title class="inm-headline">
  &nbsp;&nbsp;{{pagetitle}}</h3>

<div mat-dialog-content>
  <div fxLayout="row wrap" fxLayout.sm="column" class="col-3" fxLayoutWrap fxLayoutGap="0.5%" fxLayoutAlign="left">
    <form [formGroup]="contractForm" [style.fontSize.px]="14" class="form-content">
      <div>
        <mat-form-field appearance="outline" color="accent" fxFlexOffset="1">
          <mat-label>Effective Date Range</mat-label>
          <mat-date-range-input [rangePicker]="picker" required>
            <input (dateChange)="checkSaveEnabled()" matStartDate formControlName="effectiveStartDate"
              placeholder="Effective Start">
            <input (dateChange)="checkSaveEnabled()" matEndDate formControlName="effectiveEndDate"
              placeholder="Effective End">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
    </form>
    <div class="float-child" style="width: 100%;">
      <section class='inm-actions--confirmation'>
        <button class="inm-button--action button-spacing" [disabled]="saveDisabled"
          (click)="saveContract()">Save</button>
        <button class="inm-button button-spacing" [disabled]="cancelDisabled" [mat-dialog-close]="true">Cancel</button>
      </section>
    </div>
  </div>