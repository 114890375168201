import { DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { activateContract } from 'src/app/models/activateContract';
import { ContractService } from 'src/app/services/contractService';

import { ContractCreateEditDialogData } from '../../create-edit/ContractCreateEditDialogDataModel';
import { CreateEditContractsComponent } from '../../create-edit/create-edit-contracts.component';
import { activateContractDataMode } from '../activate-contract-data-model';

declare var require: any

@Component({
  selector: 'app-activate-contract-dialog',
  templateUrl: './activate-contract-dialog.component.html',
  styleUrls: ['./activate-contract-dialog.component.scss']
})
export class ActivateContractDialogComponent {

  contractForm: UntypedFormGroup;
  pagetitle = "Activate Contract";
  saveDisabled = true;
  cancelDisabled = false;
  contracId: number;


  constructor(
    private fb: UntypedFormBuilder,
    private contractService: ContractService,
    private datapipe: DatePipe,
    public dialogRef: MatDialogRef<CreateEditContractsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: activateContractDataMode,
  ) { }

  ngOnInit() {
    this.contracId = this.data.contractId;
    this.initValidators();
  }

  initValidators(): void {
    const moment = require('moment');
    var strEffectiveStartDate = this.data.strDate ? moment(this.data.strDate).format('YYYY/MM/DD') : null;
    var strEffectiveEndDate = this.data.endDate ? moment(this.data.endDate).format('YYYY/MM/DD') : null;
    this.saveDisabled = (strEffectiveStartDate == null || strEffectiveEndDate == null) ? true : false;
    var currentEffectiveStartDate = strEffectiveStartDate != null && strEffectiveStartDate != "Invalid date" ? new Date(strEffectiveStartDate) : '';
    var currentEffectiveEndDate = strEffectiveEndDate != null && strEffectiveEndDate != "Invalid date" ? new Date(strEffectiveEndDate) : '';

    this.contractForm = this.fb.group({
      effectiveStartDate: [currentEffectiveStartDate, Validators.compose([Validators.required])],
      effectiveEndDate: [currentEffectiveEndDate, Validators.compose([Validators.required])]
    })
  }

  checkSaveEnabled(): void {
    if (!this.data.strDate) {
      this.saveDisabled =
        this.contractForm.pristine ||
        this.contractForm.invalid;
    }
    else {
      this.saveDisabled = false;
    }

  };

  saveContract() {
    this.saveDisabled = true;
    this.cancelDisabled = true;

    const moment = require('moment');
    var strEffectiveStartDate = moment(this.contractForm.controls.effectiveStartDate.value).format('YYYY-MM-DD');
    var strEffectiveEndDate = this.contractForm.controls.effectiveEndDate.value ? moment(this.contractForm.controls.effectiveEndDate.value).format('YYYY-MM-DD') : null;

    const activate: activateContract =
    {
      EffectiveStartDate: strEffectiveStartDate,
      EffectiveEndDate: strEffectiveEndDate
    }

    this.contractService.activateContractById(activate, this.contracId).subscribe((results) => {
      const returnData: ContractCreateEditDialogData = {
        action: 'close',
        contractId: this.data.contractId,
        clientId: this.data.clientId,
        contract: results
      }

      this.saveDisabled = false;
      this.cancelDisabled = false;
      //this.isLoadingResults = false;
      this.dialogRef.close(returnData);
      toastr.success('Contract was updated successfully!');

    },
      (err) => {
        this.cancelDisabled = false;
        toastr.error('We are unable to update contract at this time');
      })

  }


}
