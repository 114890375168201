import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContractRate } from 'src/app/models/ContractRate';
import { SelectItem } from 'src/app/models/SelectItem';
import { ContractRateService } from 'src/app/services/contractRateService';
import { ContractRateTypeService } from 'src/app/services/contractRateTypeService';
import { PriceTypeCodeService } from 'src/app/services/priceTypeCodeService';

import { AddEditRateDialogData } from './add-edit-rate-dialog-data';

declare var require: any

@Component({
  selector: 'app-add-edit-rate-dialog',
  templateUrl: './add-edit-rate-dialog.component.html',
  styleUrls: ['./add-edit-rate-dialog.component.scss']
  })
export class AddEditRateDialogComponent implements OnInit {
  editMode:boolean = true;
  saveDisabled:boolean = true;
  isLoadingResults:boolean = false;
  rateForm: FormGroup;
  rateToEdit: ContractRate;
  rate: ContractRate;
  pageTitle: string = 'Edit Contract Rate';
  isTraditionalRateType: boolean = true;
  daysSupply: number;
  contractId: number;

  num4DRegex = /^-?\d{0,2}[.,]?\d{0,4}$/;
  num2DRegex = /^-?\d*[.,]?\d{0,2}$/;

  daysSupplyTooltipText = 'When entering the days supply at the rate level, the user must enter the days supply for each rate. ' +
  'For example, the user would need to enter the days supply for both brand and generic rates. From there, the user will determine ' +
  'if the value entered is for non extended days supply or extended days supply. The user can determine this by selecting either ' +
  'Non EDS or EDS in the Rate Type drop down. For example, if a user is entering a rate with the days supply of 1-83, the user ' +
  'would enter 83 in the days supply section and select Non EDS for the rate type (Both Brand and Generic). On the other hand, ' +
  'if the user is entering a rate with days supply of 84+, the user would enter 84 in the days supply section and select EDS for ' +
  'the rate type (Both Brand and Generic).';

  constructor(
    public dialogRef: MatDialogRef<AddEditRateDialogData>,
    private fb: FormBuilder,
    private rateService: ContractRateService,
    private contactRateTypeService: ContractRateTypeService,
    private priceTypeCodeService: PriceTypeCodeService,
    public datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: AddEditRateDialogData
  ) {
    if(this.data.action === 'add') {
      this.editMode = false;
      this.pageTitle = 'Add Contract Rate';
      this.daysSupply = this.data.daysSupply;
    }

    if (this.data.rateType === 'effective') {
      this.isTraditionalRateType = false;
    }

    this.contractId = this.data.contractId;
  }

  ngOnInit(): void {
    this.loadAssessmentWindowList();
    this.loadContractRateTypes();
    this.loadDirTypeList();
    this.loadPriceTypeCodes();

    this.rateForm = this.fb.group({
      rateIdCtrl: [''],
      descriptionCtrl: ['',
        Validators.compose([
          Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)
        ]),
      ],
      rateTypeCtrl: ['',
        Validators.compose([
          Validators.required
        ]),
      ],
      priceTypeCtrl: ['',
        Validators.compose([
          Validators.required
        ]),
      ],
      // made null and not ' ' here due to how the ngx-currency handles ' '
      // null allows the input box to start empty where as ' ' pre-fills the input as $0.00
      appliedRateCtrl: [null,
        Validators.compose([
          Validators.required,
          Validators.min(-99.99),
          Validators.max(99.99),
          Validators.pattern(this.num2DRegex)
        ])
      ],
      // made null and not ' ' here due to how the ngx-currency handles ' '
      // null allows the input box to start empty where as ' ' pre-fills the input as $0.00
      dispensingFeeCtrl: [null,
        Validators.compose([
          Validators.required,
          Validators.min(0),
          Validators.max(999.99),
          Validators.pattern(this.num2DRegex)
        ])
      ],
      dirTypeCtrl: [''],
      assessmentWindowCtrl: [''],
      // made null and not ' ' here due to how the ngx-currency handles ' '
      // null allows the input box to start empty where as ' ' pre-fills the input as $0.00
      dirAmountCtrl: [null,
        Validators.compose([
          Validators.min(0),
          Validators.pattern(this.num2DRegex)
        ])
      ],
      daysSupply: ['',
        Validators.compose([
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.min(1),
          Validators.max(365)
        ])
      ],
      adminOrTransactionFeeCtrl: ['',
        Validators.compose([
          Validators.pattern(this.num4DRegex)
        ])
      ],
      rateEffectiveStartDateCtrl: ['',
        Validators.compose([
          Validators.required
        ])],
      rateEffectiveEndDateCtrl: ['',
        Validators.compose([
          Validators.required
        ])]
    });

    this.populateFields();

  }

  populateFields() {
    this.rate = this.data.rate;
    const moment = require('moment');
    if (this.editMode) {
      var strEffectiveStartDate = moment(this.rate.rateEffectiveStartDate).format('YYYY/MM/DD');
      var strEffectiveEndDate = this.rate.rateEffectiveEndDate ? moment(this.rate.rateEffectiveEndDate).format('YYYY/MM/DD') : null;

      var currentEffectiveStartDate = new Date(strEffectiveStartDate);
      var currentEffectiveEndDate = strEffectiveEndDate != null && strEffectiveEndDate != "Invalid date" ? new Date(strEffectiveEndDate) : null;

      this.rateForm.controls.rateIdCtrl.setValue(this.rate.rateId);
      this.rateForm.controls.descriptionCtrl.setValue(this.rate.description);
      this.rateForm.controls.rateTypeCtrl.setValue(this.rate.rateTypeId);
      this.rateForm.controls.priceTypeCtrl.setValue(this.rate.priceTypeCodeId);
      this.rateForm.controls.appliedRateCtrl.setValue(this.rate.appliedRate);
      this.rateForm.controls.dispensingFeeCtrl.setValue(this.rate.dispensingFee);
      this.rateForm.controls.dirTypeCtrl.setValue(this.rate.dirType);
      this.rateForm.controls.assessmentWindowCtrl.setValue(this.rate.assessmentWindowId);
      this.rateForm.controls.dirAmountCtrl.setValue(this.rate.dirAmount);
      this.rateForm.controls.adminOrTransactionFeeCtrl.setValue(this.rate.adminOrTransactionFee);
      this.rateForm.controls.rateEffectiveStartDateCtrl.setValue(currentEffectiveStartDate);
      this.rateForm.controls.rateEffectiveEndDateCtrl.setValue(currentEffectiveEndDate);
      this.rateToEdit = this.rate;
      this.daysSupply = this.rate.daysSupply;
      this.rateForm.controls.daysSupply.setValue(this.rate.daysSupply);
    } else {
      this.rateToEdit = this.rate;
      this.rateForm.controls.assessmentWindowCtrl.setValue('');
      this.rateForm.controls.daysSupply.setValue(this.rate.daysSupply);
    }
  }


  assessmentWindowList: SelectItem[];
  loadAssessmentWindowList(): void {
    this.assessmentWindowList = [{label: 'Quarterly', value: 1}, {label: 'Trimester', value: 2},
    {label: 'Bi-Annually', value: 3}, {label:'Annually', value: 4}]
  }

  dirTypeList: SelectItem[];
  loadDirTypeList(): void {
    this.dirTypeList = [{label: 'Flat Fee', value: 'Flat Fee'}, {label: 'Percentage', value: 'Percentage'}];
  }

  contractRateTypeListTraditional: SelectItem[];
  contractRateTypeListEffective: SelectItem[];
  loadContractRateTypes(): void {
    this.isLoadingResults = true;
    this.contactRateTypeService.getContractRateTypes().subscribe({
      next: (result) => {
        let traditionalRates = result.filter(rt => rt.isTraditional == true);
        let effectiveRates = result.filter(rt => rt.isTraditional == false);

        this.contractRateTypeListTraditional = traditionalRates.map((a) => ({
          value: a.rateTypeId,
          label: a.description
        }
        ));

        this.contractRateTypeListEffective = effectiveRates.map((a) => ({
          value: a.rateTypeId,
          label: a.description
        }))
        this.isLoadingResults = false;
      },
      error: (err) => {
        toastr.error('We are unable to load Contract Rate Types at this time');
        this.isLoadingResults = false;
      },
    });
  }

  priceTypeCodesList: SelectItem[];
  loadPriceTypeCodes(): void {
    this.isLoadingResults = true;
    this.priceTypeCodeService.getPriceTypeCodeList().subscribe({
      next: (result) => {
        this.priceTypeCodesList = result.map((a) => ({
          value: a.priceTypeCodeId,
          label: a.description
        }
        ));

        this.isLoadingResults = false;
      },
      error: (err) => {
        toastr.error('We are unable to load Price Type Codes at this time');
        this.isLoadingResults = false;
      },
    });
  }

  compareObjectValues(o1: any, o2: any): boolean {
    return o1 === o2;
  }

  /**
   * Check if save is enabled
   */
  checkSaveEnabled(): void {
    this.saveDisabled =
      this.rateForm.pristine ||
      this.rateForm.invalid ||
      this.checkValuesChange();
  }

  checkValuesChange(): boolean {
    if (this.editMode) {
      const rateType = this.rateForm.controls.rateTypeCtrl.value;
      const priceType = this.rateForm.controls.priceTypeCtrl.value;
      const appliedRate = this.rateForm.controls.appliedRateCtrl.value;
      const dispensingFee = this.rateForm.controls.dispensingFeeCtrl.value;
      const dirType = this.rateForm.controls.dirTypeCtrl.value;
      const assessmentWindow = this.rateForm.controls.assessmentWindowCtrl.value;
      const dirAmount = this.rateForm.controls.dirAmountCtrl.value;
      const daysSupply = this.rateForm.controls.daysSupply.value;
      const adminOrTransactionFee = this.rateForm.controls.adminOrTransactionFeeCtrl.value;
      const effectiveStartDate = this.rateForm.controls.rateEffectiveStartDateCtrl.value ? this.datePipe.transform(this.rateForm.controls.rateEffectiveStartDateCtrl.value, 'yyyy-MM-dd') : "";
      const effectiveEndDate = this.rateForm.controls.rateEffectiveEndDateCtrl.value ? this.datePipe.transform(this.rateForm.controls.rateEffectiveEndDateCtrl.value, 'yyyy-MM-dd') : "";

      this.daysSupply = daysSupply;

      var valuesHaveNotChanged: boolean = (
        rateType === this.rateToEdit.rateTypeId &&
        priceType === this.rateToEdit.priceTypeCodeId &&
        appliedRate === this.rateToEdit.appliedRate &&
        dispensingFee === this.rateToEdit.dispensingFee &&
        dirType === this.rateToEdit.dirType &&
        assessmentWindow === this.rateToEdit.assessmentWindowId &&
        dirAmount === this.rateToEdit.dirAmount &&
        daysSupply === this.rateToEdit.daysSupply &&
        adminOrTransactionFee === this.rateToEdit.adminOrTransactionFee &&
        effectiveStartDate === (this.rateToEdit.rateEffectiveStartDate ? this.datePipe.transform(this.rateToEdit.rateEffectiveStartDate, 'yyyy-MM-dd') : "") &&
        effectiveEndDate === (this.rateToEdit.rateEffectiveEndDate ? this.datePipe.transform(this.rateToEdit.rateEffectiveEndDate, 'yyyy-MM-dd') : "")
      );
      return valuesHaveNotChanged;
    }
    return false;
  }

  saveRate() {
    const moment = require('moment');
    var strEffectiveStartDate = moment(this.rateForm.controls.rateEffectiveStartDateCtrl.value).format('YYYY-MM-DD');
    var strEffectiveEndDate = this.rateForm.controls.rateEffectiveEndDateCtrl.value ? moment(this.rateForm.controls.rateEffectiveEndDateCtrl.value).format('YYYY-MM-DD') : null;

    const contractRateRequestModel: any = {
      description: null,
      rateTypeId: this.rateForm.controls.rateTypeCtrl.value,
      daysSupply: this.rateForm.controls.daysSupply.value,
      priceTypeCodeId: this.rateForm.controls.priceTypeCtrl.value,
      appliedRate: this.rateForm.controls.appliedRateCtrl.value,
      rateEffectiveStartDate: strEffectiveStartDate,
      rateEffectiveEndDate: strEffectiveEndDate,
      dirType: this.rateForm.controls.dirTypeCtrl.value,
      // we want dirAmount sent to DB as 0 if no value supplier. Number( ) will cast empty string and null to 0 to achieve this
      // as well as cast the value to a float
      dirAmount: Number(this.rateForm.controls.dirAmountCtrl.value),
      dispensingFee: this.rateForm.controls.dispensingFeeCtrl.value,
      assessmentWindowId: this.rateForm.controls.assessmentWindowCtrl.value == "" ? null : this.rateForm.controls.assessmentWindowCtrl.value
    };
    if (this.editMode) {
      this.isLoadingResults = true;
      this.rateService.updateContractRateById(contractRateRequestModel, this.contractId, this.rateToEdit.rateId)
        .subscribe({
          next: (result) => {

            const returnData: AddEditRateDialogData = {
              action: 'close',
              rateType: this.rateToEdit.isTraditional ? 'traditional' : 'effective',
              rate: result,
              daysSupply: result.daysSupply,
              title: 'Edit Rate',
              contractId: this.contractId
            }
            this.isLoadingResults = false;
            this.dialogRef.close(returnData);
            toastr.success('Rate was updated successfully!');
          },
          error: (err) => {
            toastr.error('We are unable to update the rate at this time.', err);
            this.isLoadingResults = false;
          }
        });
    } else {
      this.isLoadingResults = true;
      this.rateService.addRate(contractRateRequestModel, this.contractId)
        .subscribe({
          next: (result) => {

            const returnData: AddEditRateDialogData = {
              action: 'close',
              rateType: this.isTraditionalRateType ? 'traditional' : 'effective',
              rate: result[0],
              daysSupply: result[0].daysSupply,
              title: 'Add Rate',
              contractId: this.contractId
            }
            this.isLoadingResults = false;
            this.dialogRef.close(returnData);
            toastr.success('Rate was added successfully!');
          },
          error: (err) => {
            toastr.error('We are unable to add the rate at this time.', err);
            this.isLoadingResults = false;
          }
        });
    }

  }

}
