<h3 mat-dialog-title class="inm-headline">
  &nbsp;&nbsp;{{pagetitle}}</h3>

<div mat-dialog-content>
  <div fxLayout="row wrap" fxLayout.sm="column" class="col-3" fxLayoutWrap fxLayoutGap="0.5%" fxLayoutAlign="left">
    <form [formGroup]="contractForm" [style.fontSize.px]="14" class="form-content">
      <div>
        <mat-form-field style="width: 33%;" appearance="outline" color="accent" fxFlexOffset="1">
          <mat-label>Network Name</mat-label>
          <input (input)="checkSaveEnabled()" id="networkName" type="text" title="Network Name" matInput
            formControlName="networkName" required maxlength="50" noWhitespace>
        </mat-form-field>
        <mat-form-field style="width: 33%;" appearance="outline" color="accent" fxFlexOffset="1">
          <mat-label>Payer</mat-label>
          <mat-select (selectionChange)="checkSaveEnabled()" title="Payer" required panelClass="selectbox-panel"
            formControlName="payerGroup" [compareWith]="compareObjectLabels">
            <input #matInputPayerSearch placeholder="Payer" matInput type="text"
              (keyup)="onKeyPayer($any($event.target).value)">
            <mat-option *ngFor="let payer of filteredPayers" [value]="payer">
              {{payer.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field style="width: 33%;" appearance="outline" color="accent" fxFlexOffset="1">
          <mat-label>Contract Type</mat-label>
          <mat-select (selectionChange)="checkSaveEnabled()" title="Contract Type" required formControlName="contractTypes"
            [compareWith]="compareObjectValues" multiple>
            <mat-option *ngFor="let contractType of contractTypes" [value]="contractType" [selected]="contractTypeList">
              {{contractType.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field style="width: 33%;" appearance="outline" color="accent" fxFlexOffset="1">
          <mat-label>Payment Terms</mat-label>
          <input (input)="checkSaveEnabled()" required type="number" placeholder="Payment Terms" title="Payment Terms"
            matInput formControlName="paymentTermDays" maxlength="4" noWhitespace [matAutocomplete]="autoEdit">
          <mat-autocomplete #autoEdit="matAutocomplete" ngDefaultControl autoActiveFirstOption
            (optionSelected)="onKeyPaymentTermDays($event.option); checkSaveEnabled()">
            <mat-option *ngFor="let option of paymentTermsList" [value]="option">
              {{option.label}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field style="width: 33%;" appearance="outline" color="accent" fxFlexOffset="1">
          <mat-label>Transaction Fee</mat-label>
          <input (ngModelChange)="checkSaveEnabled()" title="Transaction Fee" matInput formControlName="transactionFee"
            type="text" currencyMask [options]="{align: 'left',nullable: true }">
        </mat-form-field>
      </div>
      <div>
        <mat-form-field style="width: 33%;" appearance="outline" color="accent" fxFlexOffset="1">
          <mat-label>Effective Date Range</mat-label>
          <mat-date-range-input [rangePicker]="picker" required>
            <input (dateChange)="checkSaveEnabled()" matStartDate formControlName="effectiveStartDate"
              placeholder="Effective Start">
            <input (dateChange)="checkSaveEnabled()" matEndDate formControlName="effectiveEndDate"
              placeholder="Effective End">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
        <mat-form-field style="width: 33%;" appearance="outline" color="accent" fxFlexOffset="1">
          <mat-label>True Up</mat-label>
          <mat-select (selectionChange)="checkSaveEnabled()" title="True Up" formControlName="trueUp"
            [compareWith]="comparePaymentTermValues">
            <mat-option>None</mat-option>
            <mat-option *ngFor="let trueUp of trueUpValuesList" [value]="trueUp" [selected]="trueUpValuesList">
              {{trueUp.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field style="width: 33%;" appearance="outline" color="accent" fxFlexOffset="1">
          <mat-label>True Up Date</mat-label>
          <input matInput (dateChange)="checkSaveEnabled()" title="True Up Date" [matDatepicker]="pickerTrueUpDate"
            formControlName="trueUpDate">
          <mat-datepicker-toggle matSuffix [for]="pickerTrueUpDate"></mat-datepicker-toggle>
          <mat-datepicker #pickerTrueUpDate></mat-datepicker>
        </mat-form-field>
        <mat-form-field style="width: 33%;" appearance="outline" color="accent" fxFlexOffset="1">
          <mat-label>Days Supply</mat-label>
          <input (input)="checkSaveEnabled()" id="daysSupply" type="text" matInput
            formControlName="daysSupply" title="Days Supply" required maxlength="5" noWhitespace>
        </mat-form-field>
        <mat-form-field style="width: 33%;" appearance="outline" color="accent" fxFlexOffset="1">
          <mat-label>Classification</mat-label>
          <mat-select (selectionChange)="checkSaveEnabled()" title="Classification" formControlName="contractClassificationId"
            [compareWith]="compareObjectValues">
            <mat-option>None</mat-option>
            <mat-option *ngFor="let contractClassification of contractClassificationList" [value]="contractClassification" [selected]="contractClassificationList">
              {{contractClassification.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
  </div>
  <div>
    <div class="loading-shade" *ngIf="isLoadingResults">
      <mat-spinner color="accent" fxFlexOffset="50" fxFlexAlign="center" *ngIf="isLoadingResults">
      </mat-spinner>
    </div>
  </div>
  <div class="float-child" style="width: 100%;">
    <section class='inm-actions--confirmation'>
      <button class="inm-button--action button-spacing" [disabled]="saveDisabled" (click)="saveContract()">Save</button>
      <button class="inm-button button-spacing" [disabled]="isLoadingResults" [mat-dialog-close]="true">Cancel</button>
    </section>
  </div>
</div>
